import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountPrice, getPercentOff } from '../../helpers/product';
import { Link } from 'react-router-dom';
import { decreaseQuantity, decreaseRetailerQuantity, deleteFromCart, deleteProductFromRetailerCart, increaseQuantity, increaseRetailerQuantity, removeFromRetailerCart } from '../../store/slices/cart-slice';
import { computeTotals } from '../../helpers/cartCalculations'
const RetailerCartSummaryList = ({
    retailerCart,
    handleSelectCarts,
    setTierDisount,
    setCartTotalDiscountedPrice,
    setCartTotalPrice,
    setTotalDiscount,
    wishlistItems,
    addToWishlist }) => {
    const { address } = useSelector((state) => state.store);
    const { user } = useSelector((state) => state.auth);
    const currency = useSelector((state) => state.currency);
    const dispatch = useDispatch()
    useEffect(() => {
        const { CartTotalDiscountedPriceTemp, CartTotalPriceTemp, tierTemp, totalDiscountTemp } = computeTotals(retailerCart, user, currency);
        setCartTotalDiscountedPrice(CartTotalDiscountedPriceTemp);
        setCartTotalPrice(CartTotalPriceTemp);
        setTierDisount(tierTemp);
        setTotalDiscount(totalDiscountTemp);

    }, [retailerCart])

    return (
        <>
            <div>{
                retailerCart?.map((item, index) => (
                    item.cartItems.length > 0 && (<>
                        <div className="card my-2 p-2" key={index}>
                            <div className="form-check">
                                <input
                                    className="cart-checkbox"
                                    type="checkbox"
                                    name={item.storeId}
                                    onChange={handleSelectCarts}
                                    id={item.storeId}
                                />
                                <label className="form-check-label mx-2" htmlFor={item.storeId}>
                                    {
                                        address?.find((i) => i._id === item?.storeId)?.storeName
                                    }
                                </label>
                            </div>
                            <div className="table-content table-responsive cart-table-content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Product Name</th>
                                            <th>Unit Price</th>
                                            <th>Qty</th>
                                            <th>Subtotal</th>
                                            <th>Whislist</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            item?.cartItems?.map((cartItem, key) => {
                                                const finalProductPrice = user?.type === "Retailer" ? cartItem?.retailerPrice : user?.type === "Dropshipper" ? cartItem?.dropshipperPrice : cartItem?.price;
                                                const finalDiscount = user?.type === "Retailer" ? cartItem?.retailerDiscount : user?.type === "Dropshipper" ? cartItem?.dropshipperDiscount : cartItem?.consumerDiscount;
                                                const percentOff = getPercentOff(finalProductPrice, finalDiscount)
                                                const finalDiscountedPrice = +(finalDiscount * currency.currencyRate)?.toFixed(2);
                                                console.log("cartItem", percentOff)
                                                return (
                                                    <tr key={key}>
                                                        <td className="product-thumbnail">
                                                            <Link
                                                                to={
                                                                    process.env.PUBLIC_URL +
                                                                    "/product/" +
                                                                    cartItem._id
                                                                }
                                                            >
                                                                <img
                                                                    className="img-fluid"
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        cartItem.images[0]
                                                                    }
                                                                    alt=""
                                                                />
                                                            </Link>
                                                        </td>

                                                        <td className="product-name">
                                                            <Link
                                                                to={
                                                                    process.env.PUBLIC_URL +
                                                                    "/product/" +
                                                                    cartItem._id
                                                                }
                                                            >
                                                                {cartItem.name}
                                                            </Link>
                                                            {cartItem.selectedProductColor &&
                                                                cartItem.selectedProductSize ? (
                                                                <div className="cart-item-variation">
                                                                    <span>
                                                                        Color: {cartItem.selectedProductColor}
                                                                    </span>
                                                                    <span>
                                                                        Size: {cartItem.selectedProductSize}
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </td>

                                                        <td className="product-price-cart">
                                                            {finalDiscount !== null ? (
                                                                <Fragment>
                                                                    {
                                                                        percentOff !== null && (
                                                                            <span className="amount old">
                                                                                {currency.currencySymbol +
                                                                                    finalProductPrice}
                                                                            </span>)
                                                                    }

                                                                    <span className="amount">
                                                                        {currency.currencySymbol +
                                                                            finalDiscountedPrice}
                                                                    </span>
                                                                </Fragment>
                                                            ) : (
                                                                <span className="amount">
                                                                    {currency.currencySymbol +
                                                                        finalProductPrice}
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td className="product-quantity">
                                                            <div className="cart-plus-minus">
                                                                <button
                                                                    className="dec qtybutton"
                                                                    onClick={() => {

                                                                        user?.type === "Retailer" ? dispatch(decreaseRetailerQuantity(cartItem)) : dispatch(decreaseQuantity(cartItem))

                                                                    }
                                                                    }
                                                                >
                                                                    -
                                                                </button>
                                                                <input
                                                                    className="cart-plus-minus-box"
                                                                    type="text"
                                                                    value={cartItem.quantity}
                                                                    readOnly
                                                                />
                                                                <button
                                                                    className="inc qtybutton"
                                                                    onClick={() => {
                                                                        if (cartItem !== undefined &&
                                                                            cartItem.quantity < cartItem.stock
                                                                        ) {
                                                                            user?.type === "Retailer" ? dispatch(increaseRetailerQuantity(cartItem)) : dispatch(increaseQuantity(cartItem))
                                                                        }
                                                                    }
                                                                    }
                                                                    disabled={
                                                                        cartItem !== undefined &&
                                                                        cartItem.quantity >= cartItem.stock
                                                                    }
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="product-subtotal">
                                                            {finalDiscount !== null
                                                                ? currency.currencySymbol +
                                                                (
                                                                    finalDiscountedPrice * cartItem.quantity
                                                                )?.toFixed(2)
                                                                : currency.currencySymbol +
                                                                (
                                                                    finalProductPrice * cartItem.quantity
                                                                )?.toFixed(2)}
                                                        </td>

                                                        <td className="product-wishlist-cart">
                                                            <button
                                                                className={
                                                                    wishlistItems.includes(cartItem)
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                disabled={wishlistItems.includes(cartItem)}
                                                                title={
                                                                    wishlistItems.includes(cartItem)
                                                                        ? "Added to wishlist"
                                                                        : "Add to wishlist"
                                                                }
                                                                onClick={() => dispatch(addToWishlist(cartItem))}
                                                            >
                                                                {wishlistItems.includes(cartItem)
                                                                    ? "Added"
                                                                    : "Add to wishlist"}
                                                            </button>
                                                        </td>

                                                        <td className="product-remove">
                                                            <button
                                                                onClick={() => {
                                                                    if (user?.type === "Retailer") {
                                                                        dispatch(deleteProductFromRetailerCart(cartItem.cartItemId));
                                                                    }
                                                                    else {
                                                                        dispatch(deleteFromCart(cartItem.cartItemId));
                                                                    }

                                                                }
                                                                }
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </>)
                ))
            }</div>
        </>
    )
}

export default RetailerCartSummaryList