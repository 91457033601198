import React from "react";

const ProductPrice = ({ finalProductPrice, finalDiscount, currency }) => {
  if (finalProductPrice === undefined) return null;

  return (
    <div className="product-details-price">
      {finalDiscount !== null && finalDiscount < finalProductPrice && finalDiscount!==0? (
        <>
          <del className="old">
            {currency.currencySymbol + finalProductPrice}
          </del>
          &nbsp;&nbsp;
          <span>{currency.currencySymbol + finalDiscount}</span>
        </>
      ) : (
        <span>{currency.currencySymbol + finalProductPrice}</span>
      )}
    </div>
  );
};

export default ProductPrice;
