import { useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from "swiper";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import Swiper, { SwiperSlide } from "../../components/swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  "pk_live_51NydC4Iu9thAb9wR7iYMwRdkyAi7Lc7R3p8qQveQP1byllCRk1WsMwCLGIKDiEkqRBvUKBKN1Ma72i2nfEhVpeC500xuKtkB2X"
);



function ProductModal({
  product,
  currency,
  discountedPrice,
  finalProductPrice,
  finalDiscountedPrice,
  show,
  onHide,
  wishlistItem,
  compareItem,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  // const [selectedProductColor, setSelectedProductColor] = useState(
  //   product?.variation ? product?.variation[0].color : ""
  // );
  // const [selectedProductSize, setSelectedProductSize] = useState(
  //   product?.variation ? product?.variation[0].size[0].name : ""
  // );
  // const [productStock, setProductStock] = useState(
  //   product?.variation ? product?.variation[0].size[0].stock : product?.stock
  // );

  //eslint-disable-next-line
  const [productStock, setProductStock] = useState(product?.stock);

  const [quantityCount, setQuantityCount] = useState(1);
  const productCartQty = getProductCartQuantity(
    cartItems,
    product
    // selectedProductColor,
    // selectedProductSize
  );

  const cartItem = cartItems.find((cartItem) => cartItem.id === product?.id);

  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  };

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: true,
  };

  const onCloseModal = () => {
    setThumbsSwiper(null);
    onHide();
  };

  const discountPrice = +(finalDiscountedPrice * 100)?.toFixed(2);
  const productPrice = +(finalProductPrice * 100)?.toFixed(2);

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      className="product-quickview-modal-wrapper"
    >
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body">
        <div className="row">
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div className="product-large-image-wrapper">
              <Swiper options={gallerySwiperParams}>
                {product?.images &&
                  product?.images.map((img, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="single-image">
                          <img
                            src={process.env.PUBLIC_URL + img}
                            className="img-fluid"
                            alt="Product"
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
            <div className="product-small-image-wrapper mt-15">
              <Swiper options={thumbnailSwiperParams}>
                {product?.images &&
                  product?.images.map((img, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="single-image">
                          <img
                            src={process.env.PUBLIC_URL + img}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
          <div className="col-md-7 col-sm-12 col-xs-12">
            <div className="product-details-content quickview-content">
              <h2>{product?.name}</h2>
              <p>Reference: {product?.reference}</p>
              <div className="product-details-price flex-column align-items-start">
                {
                  finalProductPrice !== undefined ? (

                    discountedPrice !== null ? (
                      <>
                        <div>
                          <del className="old">
                            {currency.currencySymbol + finalProductPrice}
                          </del>&nbsp;&nbsp;
                          <span>
                            {currency.currencySymbol + finalDiscountedPrice}
                          </span>
                        </div>
                        <br />
                        <div>
                          <Elements stripe={stripePromise}>
                            <PaymentMethodMessagingElement options={{
                              amount: discountPrice,
                              currency: 'USD',
                              paymentMethodTypes: ['affirm'],
                              countryCode: 'US'
                            }}
                            />
                          </Elements>
                        </div>
                      </>
                    ) :
                      (
                        <div>
                          <div>
                            <span>{currency.currencySymbol + finalProductPrice} </span>
                          </div>

                          <div>
                            <Elements stripe={stripePromise}>
                              <PaymentMethodMessagingElement options={{
                                amount: productPrice,
                                currency: 'USD',
                                paymentMethodTypes: ['affirm'],
                                countryCode: 'US'
                              }}
                              />
                            </Elements>
                          </div>
                        </div>
                      )
                  ) : ""
                }
              </div>
              {product?.rating && product?.rating > 0 ? (
                <div className="pro-details-rating-wrap">
                  <div className="pro-details-rating">
                    <Rating ratingValue={product?.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="pro-details-list">
                <p dangerouslySetInnerHTML={{ __html: product?.description }}></p>
              </div>

              {/* {product?.variation ? (
              <div className="pro-details-size-color">
                <div className="pro-details-color-wrap">
                  <span>Color</span>
                  <div className="pro-details-color-content">
                    {product?.variation.map((single, key) => {
                      return (
                        <label
                          className={`pro-details-color-content--single ${single.color}`}
                          key={key}
                        >
                          <input
                            type="radio"
                            value={single.color}
                            name="product-color"
                            // checked={
                            //   single.color === selectedProductColor
                            //     ? "checked"
                            //     : ""
                            // }
                            onChange={() => {
                              // setSelectedProductColor(single.color);
                              // setSelectedProductSize(single.size[0].name);
                              setProductStock(single.size[0].stock);
                              setQuantityCount(1);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="pro-details-size">
                  <span>Size</span>
                  <div className="pro-details-size-content">
                    {product?.variation &&
                      product?.variation.map(single => {
                        return single.color === selectedProductColor
                          ? single.size.map((singleSize, key) => {
                              return (
                                <label
                                  className={`pro-details-size-content--single`}
                                  key={key}
                                >
                                  <input
                                    type="radio"
                                    value={singleSize.name}
                                    // checked={
                                    //   singleSize.name ===
                                    //   selectedProductSize
                                    //     ? "checked"
                                    //     : ""
                                    // }
                                    // onChange={() => {
                                    //   setSelectedProductSize(
                                    //     singleSize.name
                                    //   );
                                    //   setProductStock(singleSize.stock);
                                    //   setQuantityCount(1);
                                    // }}
                                  />
                                  <span className="size-name">
                                    {singleSize.name}
                                  </span>
                                </label>
                              );
                            })
                          : "";
                      })}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
              {product?.affiliateLink ? (
                <div className="pro-details-quality">
                  <div className="pro-details-cart btn-hover">
                    <a
                      href={product?.affiliateLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              ) : (
                <div className="pro-details-quality">
                  <div className="cart-plus-minus">
                    <button
                      onClick={() =>
                        setQuantityCount(
                          quantityCount > 1 ? quantityCount - 1 : 1
                        )
                      }
                      className="dec qtybutton"
                    >
                      -
                    </button>
                    <input
                      className="cart-plus-minus-box"
                      type="text"
                      value={quantityCount}
                      readOnly
                    />
                    <button
                      onClick={() =>
                        setQuantityCount(
                          quantityCount < productStock - productCartQty
                            ? quantityCount + 1
                            : quantityCount
                        )
                      }
                      className="inc qtybutton"
                    >
                      +
                    </button>
                  </div>
                  <div className="pro-details-cart btn-hover">
                    {productStock && productStock > 0 ? (
                      <button
                        onClick={() =>
                          dispatch(
                            addToCart({
                              ...product,
                              quantity: quantityCount,
                              // selectedProductColor: selectedProductColor ? selectedProductColor : product?.selectedProductColor ? product?.selectedProductColor : null,
                              // selectedProductSize: selectedProductSize ? selectedProductSize : product?.selectedProductSize ? product?.selectedProductSize : null
                            })
                          )
                        }
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    ) : (
                      <button disabled>Out of Stock</button>
                    )}
                  </div>
                  <div className="pro-details-wishlist">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => dispatch(addToWishlist(product))}
                    >
                      <i className="pe-7s-like" style={{ color: "black" }} />
                    </button>
                  </div>
                  {/* <div className="pro-details-compare">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => dispatch(addToCompare(product))}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ProductModal.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
};

export default ProductModal;
